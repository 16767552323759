.App {
  box-sizing: border-box;
  margin: 0;
  padding: 0;  
  width: 100%
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  height: 100px;
  justify-content: flex-end;
  padding-right: 20px;
  width: calc(100% - 20px);
}

.App-Content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 50px 0 50px;
  width: clac(100% - 100px)
} 

.App-DataElements {
  padding: 20px;
  width: calc(30% - 50px);
}

.App-Card {
  background: #fff;
  height: calc(100vh - 220px);
  margin-top: 20px;
}

.App-Form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  width: calc(70% - 50px);
}

.App-FormTitle {
  align-content: center;
  display: flex;
  justify-content: center;
  font-size: 18pt;
  height: 50px;
  width: 100%;
}

.App-FormHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 50px;
  width: 100%;
}

.App-FormPreview {
  align-content: flex-start;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100vh - 250px);
  justify-content: flex-start;
  overflow-y: auto;
  padding: 20px;
  width: 80%;
}

.App-FormInput {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 45%;
}

.App-FormTextArea {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 90%;
}

.App-Input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  width: 90%;
}

input, select, textarea {
  height: 30px;
  width: 100%
}

button {
  background-color: #09D3AC;
  border: none;
  border-radius: 50px;
  color: #282c34;
  cursor: pointer;
  height: 50px;
  margin-right: 10px;
  width: 200px;
}

button:hover {
  color: #FFF
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
}

.App-TitleBar {
  align-items: center;
  border-bottom: solid 2px #09D3AC;
  color: #282c34;
  display: flex;
  justify-content: flex-start;
  font-size: 14pt;
  height: 50px;
  margin-bottom: 25px;
  width: 100%;
}